import { Dialog } from "vant";
export default {
  name: "DownLoadPopWindow",

  data() {
    return {
      isShow: false
    };
  },

  created() {
    // console.log('down', this.$route.query.isShow)
    this.isShow = true;
  },

  props: {
    platform: String
  },
  methods: {
    downLoadUrl() {
      if (this.platform === 'pc') {
        Dialog({
          message: '请通过App Store或者手机应用商店下载对应平台安装文件'
        });
      } else if (this.platform === 'android') {
        Dialog({
          message: 'Android最新版本请通过手机应用商店下载'
        });
      } else if (this.platform === 'ios') {
        window.location = 'https://apps.apple.com/cn/app/id6446807775';
      } else {
        Dialog({
          message: '请通过App Store或者手机应用商店下载对应平台安装文件'
        });
      }
    }

  }
};