import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import VueParticles from 'vue-particles'
import App from './App.vue'
import {Button, Col, Dialog, Image as VanImage, Row, Swipe, SwipeItem} from 'vant';
import MyHome from "@/components/MyHome";
import 'vant/es/dialog/style';

const routes = [
    {path: '/', component: MyHome, meta: {title: '念念许愿'}},
]

const router = createRouter({
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
const app = createApp(App)

app.use(router)
app.use(VueParticles)
app.use(Button);
app.use(Row);
app.use(Col);
app.use(VanImage);
app.use(Dialog);
app.use(Swipe);
app.use(SwipeItem);
app.mount('#app')
