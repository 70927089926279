import MyFooter from "@/components/MyFooter";
import DownloadPopWindow from "@/components/DownloadPopWindow";
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import { Dialog } from "vant";
export default {
  name: 'MyHome',

  data() {
    return {
      platform: 'ios',
      ua: '',
      iosDownload: false,
      logoSrc: require('../assets/app_icon.png')
    };
  },

  props: {
    msg: {
      type: String,
      default: '简单极致的许愿工具：许下心愿，升华自我，美好祈福'
    }
  },
  methods: {
    _platform() {
      let ua = navigator.userAgent;
      this.ua = ua; //android终端

      let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //ios终端

      let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isAndroid ? 'android' : isiOS ? 'ios' : 'pc';
    },

    downloadIos() {
      this.iosDownload = !this.iosDownload;
    },

    downloadAndroid() {
      Dialog({
        message: 'Android最新版本请通过手机应用商店下载'
      });
    }

  },
  mounted: function () {
    this.platform = this._platform();
  },
  components: {
    MyFooter,
    DownloadPopWindow,
    VueQr
  }
};